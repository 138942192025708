export const Types = {
  LIST_REQUEST: "ocorrencias/LIST_REQUEST",
  LIST_SUCCESS: "ocorrencias/LIST_SUCCESS",
  NEW_REQUEST: "ocorrencias/NEW_REQUEST",
  NEW_SUCCESS: "ocorrencias/NEW_SUCCESS",
  DELETE_REQUEST: "ocorrencias/DELETE_REQUEST",
  DELETE_SUCCESS: "ocorrencias/DELETE_SUCCESS",
  GET_REQUEST: "ocorrencias/GET_REQUEST",
  GET_SUCCESS: "ocorrencias/GET_SUCCESS",
  EDIT_REQUEST: "ocorrencias/EDIT_REQUEST",
  EDIT_SUCCESS: "ocorrencias/EDIT_SUCCESS",
};

const INITIAL_STATE = {
  loading: false,
  list: [],
  ocorrencia: [],
  sort: "-dataInicio",
  page: 1,
  limit: 10,
  total: 0,
};

export default function ocorrencias(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.LIST_REQUEST:
      return { ...state, loading: true };

    case Types.LIST_SUCCESS:
      return { ...state, ...action.payload, loading: false };

    case Types.NEW_REQUEST:
      return { ...state, loading: true };

    case Types.NEW_SUCCESS:
      return { ...state, loading: false };

    case Types.DELETE_REQUEST:
      return { ...state, loading: true };

    case Types.DELETE_SUCCESS:
      return { ...state, loading: false };

    case Types.GET_REQUEST:
      return { ...state, loading: true };

    case Types.GET_SUCCESS:
      return { ...state, loading: false };

    case Types.EDIT_REQUEST:
      return { ...state, loading: true };

    case Types.EDIT_SUCCESS:
      return { ...state, loading: false };

    default:
      return state;
  }
}

export const Creators = {
  listOcorrenciasRequest: (data) => ({
    type: Types.LIST_REQUEST,
    payload: data,
  }),

  listOcorrenciasSuccess: (data) => ({
    type: Types.LIST_SUCCESS,
    payload: data,
  }),

  deleteOcorrenciasRequest: (id) => ({
    type: Types.DELETE_REQUEST,
    payload: id,
  }),

  deleteOcorrenciasSuccess: () => ({
    type: Types.DELETE_SUCCESS,
    payload: {},
  }),

  getOcorrenciasRequest: (id) => ({
    type: Types.GET_REQUEST,
    payload: id,
  }),

  getOcorrenciasSuccess: (data) => ({
    type: Types.GET_SUCCESS,
    payload: data,
  }),

  editOcorrenciasRequest: (data) => ({
    type: Types.EDIT_REQUEST,
    payload: data,
  }),

  editOcorrenciasSuccess: () => ({
    type: Types.EDIT_SUCCESS,
    payload: {},
  }),

  createOcorrenciasRequest: (data) => ({
    type: Types.NEW_REQUEST,
    payload: data,
  }),

  createOcorrenciasSuccess: () => ({
    type: Types.NEW_SUCCESS,
    payload: {},
  }),
};
