import React, { useState, useEffect, useMemo, useRef } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Skeleton from '@material-ui/lab/Skeleton';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import _ from '@lodash';
import { toastr } from 'react-redux-toastr';
import IndexerFormFieldFactory from 'components/FormFieldIndexer/IndexerFormFieldFactory';

import LoadingProcess from './LoadingProcess';
import ConfirmDialog from './ConfirmDialog';
import fileToBase64 from 'services/fileToBase64';
import api from 'services/api';
import { valida_cnpj, valida_cpf } from 'services/validadorCpfCNPJ';
import FormIndexaçãoSchema from 'validators/FormIndexacaoSchema';

import { LabelSwapper } from '../../../components/LabelSwapper';

import { Creators as EmpresasActions } from 'store/ducks/empresas';
import { Creators as DepartamentosActions } from 'store/ducks/departamentos';
import { Creators as TiposDocumentoActions } from 'store/ducks/tiposdocumento';
import { Creators as DocumentosActions } from 'store/ducks/documentos';
import { Creators as TipoImagensActions } from 'store/ducks/tipoimagem';
import { Creators as AcaoActions } from 'store/ducks/acao';
import { Creators as ProcessoActions } from 'store/ducks/processo';
import { Creators as ProvisoesActions } from 'store/ducks/provisoes';

import './styles.css';
import { ValidationError } from 'yup';
import { SearchIcon } from 'components/Icons';
import { reorderIndexadores } from 'utils/utils';

const INITIAL_FORM = {
  tipoDocumento: null,
  empresa: null,
  departamento: null,
  pasta: '',
  numeroCNJ: '',
  pais: 'Brasil',
  denominacao: '',
  assunto: '',
  causaRaiz: '',
  resumo: '',
  provisao: '',
  valorDaCausa: '',
  pedidos: '',
  centroDeCusto: '',
  posicaoParte: 'ATIVA',
};

const queryParams = { page: 1, limit: Number.MAX_SAFE_INTEGER };

function FormIndexacao({
  onChangeTipoDocumento,
  imagens,
  onSave,
  checkReadOnly = true,
}) {
  const dispatch = useDispatch();
  const dispatchDebounce = useRef(_.debounce(dispatch, 500)).current;

  const clicked = useRef(false);

  const {
    access: { empresas, relacionamentos },
  } = useSelector((state) => state.permissoes);
  const { list: departamentosList, loading: loadingDepartamentos } =
    useSelector((state) => state.departamentos);
  const { listByEmpresas: tipoDocList, loading: loadingTipoDoc } = useSelector(
    (state) => state.tipoDocumentos
  );
  const { list: empresasList, loading: loadingEmpresas } = useSelector(
    (state) => state.empresas
  );
  const { metadados, loadingMetadados, loading } = useSelector(
    (state) => state.documentos
  );

  const {
    data: processo,
    loading: loadingProcesso,
    error: errorProcesso,
  } = useSelector((state) => state.processo);

  const [form, setForm] = useState(INITIAL_FORM);
  const [formErrors, setFormErrors] = useState({});

  const [indexadores, setIndexadores] = useState(null);
  const [currentMetadata, setCurrentMetadata] = useState(null);
  const [open, setOpen] = useState({});
  const [openLoading, setOpenLoading] = useState(false);
  const [processedFiles, setProcessedFiles] = useState([]);
  const [confirmIndex, setConfirmIndex] = useState(null);
  const [processDataLoading, setProcessDataLoading] = useState(false);
  const [loadingIndexadores, setLoadingIndexadores] = useState(false);

  const listEmpresas = useMemo(
    () =>
      !form.tipoDocumento
        ? []
        : empresasList.filter((emp) =>
          form.tipoDocumento.empresas.includes(emp._id)
        ),
    [form.tipoDocumento, empresasList, processo]
  );

  const listDepartamentos = useMemo(
    () =>
      !form.empresa
        ? []
        : departamentosList.filter(
          (dep) => dep.empresa._id === form.empresa._id
        ),
    [form.empresa, departamentosList, processo]
  );

  const separadores = useMemo(
    () =>
      indexadores
        ? indexadores.reduce(
          (acc, indexador) =>
            indexador.separador && !acc.includes(indexador.separador)
              ? [indexador.separador, ...acc]
              : acc,
          ['Outros indexadores']
        )
        : null,
    [indexadores]
  );

  useEffect(() => {
    if (!processDataLoading) {
      setForm((formState) => ({
        ...formState,
        empresa: listEmpresas.length === 1 ? listEmpresas[0] : null,
      }));
    }
  }, [form.tipoDocumento, processDataLoading]);

  useEffect(() => {
    if (!processDataLoading) {
      setForm((formState) => ({
        ...formState,
        departamento:
          listDepartamentos.length === 1 ? listDepartamentos[0] : null,
      }));
    }
  }, [form.empresa, processDataLoading]);

  // CLEAR UP ON DISMOUNT
  useEffect(() => () => dispatch(ProcessoActions.clear()), [dispatch]);

  useEffect(() => {
    dispatch(EmpresasActions.listRequest());
    dispatch(TiposDocumentoActions.listByEmpresaRequest(empresas));
    dispatch(DepartamentosActions.listRequest());
    dispatch(TipoImagensActions.listRequest({ ...queryParams, sort: 'nome' }));
    dispatch(AcaoActions.listRequest(queryParams));
    dispatch(ProvisoesActions.listRequest());
  }, [dispatch, empresas, relacionamentos]);

  useEffect(() => {
    if (!loadingMetadados && metadados && metadados.length === 1) {
      const [value] = metadados;

      setIndexadores((idx) =>
        idx
          ? idx.map((indexador) => ({
            ...indexador,
            valor: value[indexador.nome],
          }))
          : null
      );
      setCurrentMetadata(value);
      setOpen({});
    }
  }, [loadingMetadados, metadados]);

  useEffect(() => {
    if (!loading && clicked && clicked.current === true) {
      clicked.current = false;
      setCurrentMetadata(null);
      setIndexadores((idx) =>
        idx
          ? idx.map((indexador) => ({
            ...indexador,
            valor: '',
          }))
          : null
      );
      onSave();
    }
  }, [loading, onSave]);

  useEffect(() => {
    if (onChangeTipoDocumento) onChangeTipoDocumento(form.tipoDocumento);
  }, [onChangeTipoDocumento, form.tipoDocumento]);

  useEffect(() => {
    async function loadOcr() {
      try {
        setOpenLoading(true);

        let newIndexadores = indexadores;
        let newProcessedFiles = processedFiles;

        for (const imagem of imagens) {
          if (
            newIndexadores.some((indexador) => !indexador.valor) &&
            !newProcessedFiles.includes(imagem.file)
          ) {
            const fileUri = await fileToBase64(imagem.file);
            const [, base64] = fileUri.split(',');

            const body = {
              apikey: form.tipoDocumento.apikey,
              image: base64,
            };

            setLoadingIndexadores(true);

            // TODO - Extract to a Service
            const { data } = await api.post('process', body);
            const { classification, extraction, text } = data;

            newIndexadores = newIndexadores.map((indexador) => {
              if (indexador.valor) return indexador;

              const fieldName =
                indexador.nomeOcr && indexador.nomeOcr !== ''
                  ? indexador.nomeOcr
                  : indexador.nome;

              const fieldExtraction = extraction.find(
                (fieldExtraction) => fieldExtraction.name === fieldName
              );

              if (fieldExtraction) {
                return {
                  ...indexador,
                  valor: fieldExtraction.value,
                };
              }
              return indexador;
            });

            newProcessedFiles.push(imagem.file);
          }
        }

        setIndexadores(newIndexadores);
        setProcessedFiles(newProcessedFiles);
        setOpenLoading(false);

        setLoadingIndexadores(false);
      } catch (err) {
        setOpenLoading(false);
        toastr.error(
          'Extração de dados',
          'Não foi possível processar o(s) documento(s)'
        );
      }
    }
    if (
      form.tipoDocumento &&
      form.tipoDocumento.apikey &&
      form.tipoDocumento.apikey !== '' &&
      indexadores
    ) {
      if (indexadores.some((indexador) => !indexador.valor)) {
        loadOcr();
      } else {
        let updatedProcessedFiles = false;
        imagens.forEach((imagem) => {
          if (!processedFiles.includes(imagem.file))
            updatedProcessedFiles = true;
        });
        if (updatedProcessedFiles)
          setProcessedFiles(imagens.map((imagem) => imagem.file));
      }
    }
  }, [imagens, form.tipoDocumento, indexadores, processedFiles]);

  useEffect(() => {
    //atualizar valor dos campos com todos os indexadores atualizados

    indexadores &&
      indexadores.forEach((indexador) => {
        setForm({
          ...form,
          [indexador.nome]: indexador.valor,
        });
      });
  }, [indexadores]);

  useEffect(() => {
    function updateFormWithProcessoData() {
      if (!loadingProcesso && !errorProcesso && !_.isEqual(processo, {})) {
        setForm((formState) => ({
          ...formState,
          ...processo,
          valorDaCausa: processo.valorDaCausa
            ? processo.valorDaCausa / 100
            : formState.valorDaCausa,
          tipoDocumento: processo.idTipoDocumento,
          empresa: processo.idEmpresa,
          departamento: processo.idDepartamento,
          UF: processo.UF,
        }));
        setIndexadores(processo.idTipoDocumento.indexadores);
      }
    }
    setProcessDataLoading(true);
    updateFormWithProcessoData();
  }, [dispatch, processo, loadingProcesso, errorProcesso]);

  useEffect(() => {
    if (setProcessDataLoading) {
      setTimeout(function () {
        setProcessDataLoading(false);
      }, 150);
    }
  }, [setProcessDataLoading]);

  function handleChangeCNJ({ target }) {
    const { value, name } = target;

    setForm({ ...form, [name]: value });
    if (value.length > 5) {
      dispatchDebounce(ProcessoActions.getRequest(value));
    }
  }

  function handleChange(event) {
    if (!event.target) {
      return;
    }

    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });
  }

  function handleChangeWithMask(event, value) {
    if (!event.target) {
      return;
    }
    setForm({
      ...form,
      [event.target.name]: value,
    });
  }

  function handleChangeIndexadorWithLodash(e) {
    dispatchDebounce(
      DocumentosActions.metadadosRequest(
        form.empresa._id,
        form.modalidade._id,
        form.tipoDocumento._id,
        e.target.name,
        e.target.value
      )
    );
    handleChangeIndexador(e);
  }

  function handleChangeIndexador({ target }) {
    const { name, value } = target;

    const newIndexadores = [...indexadores];
    const index = newIndexadores.findIndex(indexador => indexador.nome === name);

    newIndexadores[index].valor = value;

    setIndexadores(newIndexadores);
  }

  function handleChangeAutocomplete(e, value) {
    handleChangeIndexador({ target: { value: value } });
    setCurrentMetadata(value);
  }

  function checkIfFieldHaveValidValue(fieldType, indexer) {
    if (!indexer) {
      return false;
    }
    if (indexer.tipo === fieldType && indexer.valor && indexer.valor !== '') {
      return true;
    }
    return false;
  }

  function checkIfValueIsInvalid(fieldType, indexer, validator) {
    return (
      checkIfFieldHaveValidValue(fieldType, indexer) &&
      !validator(indexer.valor)
    );
  }

  function checkIfItsAInvalidCPF(indexer) {
    return checkIfValueIsInvalid('cpf', indexer, valida_cpf);
  }

  function checkIfItsAInvalidCNPJ(indexer) {
    return checkIfValueIsInvalid('cnpj', indexer, valida_cnpj);
  }

  function hasIndexadorError(indexador) {
    if (indexador.obrigatorio && !indexador.valor) return true;

    if (checkIfItsAInvalidCPF(indexador) || checkIfItsAInvalidCNPJ(indexador)) {
      return true;
    }
    return false;
  }

  function hasIndexadorTextError(indexador) {
    if (indexador.obrigatorio && !indexador.valor) return 'Campo obrigatório';

    if (checkIfItsAInvalidCPF(indexador)) {
      return 'CPF inválido!';
    }

    if (checkIfItsAInvalidCNPJ(indexador)) {
      return 'CNPJ inválido!';
    }

    return '';
  }

  function canSend() {
    if (
      indexadores &&
      indexadores.some((indexador) => indexador.obrigatorio && !indexador.valor)
    ) {
      return false;
    }

    if (
      indexadores &&
      indexadores.some(
        (indexador) =>
          checkIfItsAInvalidCPF(indexador) || checkIfItsAInvalidCNPJ(indexador)
      )
    ) {
      return false;
    }

    return true;
  }

  function handleEmptyCNJNumber(numero) {
    return numero.trim() === ''
  }

  function resetForm() {
    setForm(INITIAL_FORM)
    setIndexadores(null)
  }


  async function handleSubmit(e) {
    e.preventDefault();

    try {
      await FormIndexaçãoSchema.validate(form, { abortEarly: false });
    } catch (err) {
      if (err instanceof ValidationError) {
        const errorsObj = err.inner.reduce(
          (acc, value) => ({ ...acc, [value.path]: value.message }),
          {}
        );
        return setFormErrors(errorsObj);
      }
    }

    setFormErrors({});

    if (imagens.some((imagem) => imagem.checked && !imagem.tipoImagem)) return;

    const imagensBase64 = [];

    for (const imagem of imagens) {
      if (imagem.checked) {
        const base64 = await fileToBase64(imagem.file);
        imagensBase64.push({
          base64,
          tipoImagem: imagem.tipoImagem._id,
        });
      }
    }

    const documento = {
      ...form,
      idTipoDocumento: form.tipoDocumento?._id || '',
      idEmpresa: form.empresa?._id || '',
      idDepartamento: form.departamento?._id || '',
      indexadores: indexadores.map((indexador) => ({
        ...indexador,
        valor: form[indexador.nome] || indexador.valor || '',
      })),
      imagens: imagensBase64,
      valorDaCausa: form.valorDaCausa
        ? form.valorDaCausa.toString().replace(',', '').replace(/\./g, '')
        : null,
      posicaoParte: form.posicaoParte,
    };

    delete documento.tipoDocumento;
    delete documento.empresa;

    if (imagensBase64.length > 0) {
      clicked.current = true;
      dispatch(DocumentosActions.createRequest(documento));
    } else {
      setConfirmIndex(documento);
    }
  }

  function handleGetOptionSelected(option, value) {
    if (value) {
      return option._id === value._id;
    }
  }

  function handleConfirmDialogClose() {
    setConfirmIndex(null);
  }

  function handleConfirmDialogConfirm() {
    clicked.current = true;
    dispatch(DocumentosActions.createRequest(confirmIndex));
    resetForm();
    setConfirmIndex(null);
  }

  function displayIndexadorWithDependencia(indexador) {
    const idxs = [...indexadores];

    if (!indexador.dependenciaIndexador) {
      return "block";
    }

    const valorIdxDependencia = idxs.find(idx => indexador.dependenciaIndexador.index === idx.index).valor;

    //checar se o valor do indexador.dependenciaIndexador.index é igual ao valor do indexador.dependenciaIndexador.condicoes.valor

    const matchedCondition = indexador.dependenciaIndexador?.condicoes.find(condicao => condicao.valor === valorIdxDependencia);

    // console.log("indexador", indexador.nome, "matchedCondition", matchedCondition, !!matchedCondition);

    return !!matchedCondition === false ? "none" : "block";
  }

  /*
  function filterOptionsForDependencia(indexador) {
    const idxs = [...indexadores];

    const valorIdxDependencia = idxs.find(idx => indexador.dependenciaIndexador.index === idx.index).valor;

    const reference = idxs[indexador.index];
    //verificar dentro de dependenciaIndexador.condicoes se alguma das condicoes o valor é igual ao valor do valorIdxDependencia
    //se for igual, retornar o indexador.dependenciaIndexador.opcoes
    //se não for igual, retornar um array vazio

    const opcoesEncontradas = indexador.dependenciaIndexador.condicoes.find(condicao => condicao.valor === valorIdxDependencia)?.opcoes;

    if (opcoesEncontradas) {
      reference.opcoes = opcoesEncontradas;
    }

    return reference;
  }
  */

  useEffect(() => {
    if (form.tipoDocumento) {
      const reorderedIndexadores = reorderIndexadores(form.tipoDocumento.indexadores);
      setIndexadores(reorderedIndexadores);
      console.log('--- RESET INDEXADORES ---')//teste
    }
  }, [form.tipoDocumento]);

  return (
    <>
      <form onSubmit={handleSubmit} className="form-indexacao-container">
        <Row>
          <Col md={6}>
            <TextField
              name="numeroCNJ"
              label={
                <LabelSwapper
                  text={'Nº do cliente'}
                  parent={'FormIndexacao'}
                  name={'numeroCNJ'}
                />
              }
              onChange={handleChangeCNJ}
              value={form.numeroCNJ}
              size="small"
              variant="outlined"
              InputProps={{
                endAdornment: !!loadingProcesso ? (
                  <CircularProgress color="primary" size={20} />
                ) : (
                  <SearchIcon />
                ),
              }}
            />
          </Col>
          <Col md={6}>
            {!loadingTipoDoc ? (
              <Autocomplete
                id="combo-box-tipodocumento"
                options={tipoDocList}
                getOptionLabel={(option) => option.nome}
                size="small"
                onChange={(event, newValue) =>
                  setForm({ ...form, tipoDocumento: newValue })
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={!!formErrors.tipoDocumento}
                    helperText={
                      formErrors.tipoDocumento ? formErrors.tipoDocumento : ''
                    }
                    label="Tipo documento"
                    variant="outlined"
                  />
                )}
                value={form.tipoDocumento}
              />
            ) : (
              <Skeleton width="100%" height={50} />
            )}
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            {!loadingEmpresas ? (
              <Autocomplete
                id="combo-box-empresas"
                options={listEmpresas}
                onChange={(event, newValue) => {
                  setForm({ ...form, empresa: newValue });
                }}
                getOptionSelected={handleGetOptionSelected}
                getOptionLabel={(option) => option.fantasia}
                size="small"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Empresa"
                    variant="outlined"
                    error={!!formErrors.empresa}
                    helperText={formErrors.empresa ? formErrors.empresa : ''}
                  />
                )}
                value={form.empresa}
                disabled={!form.tipoDocumento}
              />
            ) : (
              <Skeleton width="100%" height={50} />
            )}
          </Col>
          <Col md={6}>
            {!loadingDepartamentos ? (
              <Autocomplete
                id="combo-box-departamento"
                options={listDepartamentos}
                onChange={(event, newValue) =>
                  setForm({ ...form, departamento: newValue })
                }
                getOptionSelected={handleGetOptionSelected}
                getOptionLabel={(option) => option.nome}
                size="small"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Departamento"
                    variant="outlined"
                    error={!!formErrors.departamento}
                    helperText={
                      formErrors.departamento ? formErrors.departamento : ''
                    }
                  />
                )}
                value={form.departamento}
                disabled={!form.empresa}
              />
            ) : (
              <Skeleton width="100%" height={50} />
            )}
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <TextField
              name="resumo"
              label="Resumo"
              onChange={handleChange}
              value={form.resumo}
              size="small"
              variant="outlined"
              multiline
              rows={4}
            />
          </Col>
        </Row>
        {loadingIndexadores ? (
          <>
            {' '}
            <CircularProgress color="primary" size={20} />.
          </>
        ) : (
          <>
            {separadores &&
              separadores
                .filter((separador) =>
                  indexadores.some(
                    (indexador) =>
                      (indexador.separador === separador &&
                        (indexador.invisivel === false ||
                          indexador.invisivel === undefined)) ||
                      (!indexador.separador &&
                        separador === 'Outros indexadores')
                  )
                )
                .map((separador) => (
                  <Row key={separador}>
                    <Col>
                      <Typography
                        className="separador"
                        variant="body1"
                        gutterBottom
                      >
                        {separador}
                      </Typography>
                      {indexadores
                        .filter(
                          (indexador) =>
                            (indexador.separador === separador &&
                              (indexador.invisivel === false ||
                                indexador.invisivel === undefined)) ||
                            (!indexador.separador &&
                              separador === 'Outros indexadores')
                        )
                        .map((indexador) => (
                          <Row key={indexador.nome} style={{ display: displayIndexadorWithDependencia(indexador) }}>
                            <Col md={12}>
                              <IndexerFormFieldFactory
                                genericParams={{
                                  indexador,
                                  checkReadOnly,
                                  hasIndexadorError,
                                  hasIndexadorTextError,
                                  handleChangeIndexador,
                                }}
                                identificadorUnicoParams={{
                                  metadados,
                                  currentMetadata,
                                  loadingMetadados,
                                  open,
                                  setOpen,
                                  handleChangeAutocomplete,
                                  handleChangeIndexadorWithLodash,
                                }}
                              />
                            </Col>
                          </Row>
                        ))}
                    </Col>
                  </Row>
                ))}
          </>
        )}

        <Row>
          <Col>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              disabled={!!loading || !canSend() || handleEmptyCNJNumber(form.numeroCNJ)}
            >
              {!!loading && (
                <>
                  <CircularProgress size={20} color="primary" />
                  &nbsp;
                </>
              )}
              Indexar
            </Button>
          </Col>
        </Row>
      </form>
      <LoadingProcess open={openLoading} />
      {!!confirmIndex && (
        <ConfirmDialog
          open={!!confirmIndex}
          onClose={handleConfirmDialogClose}
          onConfirm={handleConfirmDialogConfirm}
        />
      )}
    </>
  );
}

export default FormIndexacao;
