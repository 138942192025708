import React from "react";

import FieldData from "./FieldData";
import FieldCNPJOrCPF from "./FieldCNPJOrCPF";
import FieldCombo from "./FieldCombo";
import FieldDynamicMask from "./FieldDynamicMask";
import FieldGeneric from "./FieldGeneric";
import FieldIdentificadorUnico from "./FieldIdentificadorUnico";

import { FIELD_TYPES } from "constants/indexer-field-types";

function IndexerFormFieldFactory({
  genericParams,
  identificadorUnicoParams,
  fieldToAdd = null,
  ...rest
}) {
  const { indexador } = genericParams;

  if (!indexador) {
    console.log("genericParams", genericParams)
    return "";
  }

  const fieldType = indexador.indentificadorUnico
    ? FIELD_TYPES.ID_UNIQUE
    : indexador.mascara
      ? FIELD_TYPES.DYNAMIC_MASK
      : indexador.tipo && FIELD_TYPES[indexador.tipo.toUpperCase()]
        ? FIELD_TYPES[indexador.tipo.toUpperCase()]
        : FIELD_TYPES.GENERIC;

  switch (fieldType) {
    case FIELD_TYPES.COMBO:
      return <FieldCombo {...genericParams} fieldToAdd={fieldToAdd} {...rest} />;
    case FIELD_TYPES.DATA:
      return <FieldData {...genericParams}  {...rest} />;
    case FIELD_TYPES.CNPJ:
      return <FieldCNPJOrCPF {...genericParams} {...rest} />;
    case FIELD_TYPES.CPF:
      return <FieldCNPJOrCPF {...genericParams}  {...rest} />;
    case FIELD_TYPES.ID_UNIQUE:
      return (
        <FieldIdentificadorUnico
          {...rest}
          {...genericParams}
          {...identificadorUnicoParams}
        />
      );
    case FIELD_TYPES.DYNAMIC_MASK:
      return <FieldDynamicMask {...genericParams}  {...rest} />;
    case FIELD_TYPES.TABELA:
      return <></>;
    default:
      return <FieldGeneric {...genericParams} {...rest} />;
  }
}

export default IndexerFormFieldFactory;
