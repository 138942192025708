import React from 'react';

import { ConnectedRouter } from 'connected-react-router';

import { Switch, Route, Redirect } from 'react-router-dom';

import history from './history';

import Callback from '../pages/Login/callback';
import LoginV2 from '../pages/Login/index';
import Login from '../pages/Login/index-old';
import LoginWithToken from '../pages/LoginWithToken';
import TwoFactor from '../pages/Login/twoFactor';

import Empresas from '../pages/Empresas';

import FormEmpresas from '../pages/Empresas/FormEmpresas';
import EditEmpresas from '../pages/Empresas/EditEmpresas';

import Departamentos from '../pages/Departamentos';
import FormDepartamento from '../pages/Departamentos/FormDepartamento';
import EditDepartamento from '../pages/Departamentos/EditDepartamento';

import TipoDepartamento from '../pages/TipoDepartamento';
import FormTipoDepartamento from '../pages/TipoDepartamento/FormTipoDepartamento';
import EditTipoDepartamento from '../pages/TipoDepartamento/EditTipoDepartamento';

import Categorias from '../pages/Categorias';
import FormCategoria from '../pages/Categorias/FormCategoria';
import EditCategoria from '../pages/Categorias/EditCategoria';

import FormParamAssinaturaEletronic from '../pages/ParamAssinaturaEletronica';

import UF from '../pages/UF';
import FormUF from '../pages/UF/FormUF';
import EditUF from '../pages/UF/EditUF';

import Ocorrencias from '../pages/Ocorrencias';
import FormOcorrencia from '../pages/Ocorrencias/FormOcorrencia';
import EditOcorrencia from '../pages/Ocorrencias/EditOcorrencia';

import Instancia from '../pages/Instancia';
import FormInstancia from '../pages/Instancia/FormInstancia';
import EditInstancia from '../pages/Instancia/EditInstancia';

import Formularios from '../pages/Formulario';
import CheckTipoPreenchimento from '../pages/CheckTipoPreenchimento';
import FormFormulario from '../pages/Formulario/NewFormulario';
import EditFormulario from '../pages/Formulario/EditForumlario';

import Acao from '../pages/Acao';
import FormAcao from '../pages/Acao/FormAcao';
import EditAcao from '../pages/Acao/EditAcao';
import Importacao from '../pages/Importacao';
import Exportacao from 'pages/Exportacao';
import ExportacaoUNIC from 'pages/ExportacaoUNIC';
import Dashboard from '../pages/Dashboard';
import TableLayout from '../pages/Layout';
import FormLayout from '../pages/Layout/FormLayout';
import TableRPA from '../pages/RPA';
import FormRPA from '../pages/RPA/FormRPA';
import TableCarga from '../pages/Cargas';
import FormCarga from '../pages/Cargas/FormCarga';
import GrupoEmails from 'pages/GrupoDeEmail';
import EditFormLayout from '../pages/Layout/EditFormLayout';
import GrupoDeEmail from '../pages/GrupoDeEmail/FormGrupoEmail';
import EditGrupoDeEmail from '../pages/GrupoDeEmail/EditFormGrupoEmail';
import Assessoria from '../pages/Assessoria';
import FormAssessoria from '../pages/Assessoria/FormAssessoria';
import EditAssessoria from '../pages/Assessoria/EditAssessoria';

import TipoDocumentos from '../pages/TipoDocumentos';
import CreateTipoDocumento from '../pages/TipoDocumentos/CreateTipoDocumento';
import EditTipoDocumento from '../pages/TipoDocumentos/EditTipoDocumento';
import DefinicaoSLA from '../pages/TipoDocumentos/DefinicaoSLA';
import DepartamentoEscalonamento from '../pages/Departamentos/Escalonamento';
import AsynchronousResultsExecution from '../pages/AsynchronousResultsExecution';
import IndexadoresTipoDocumento from '../pages/TipoDocumentos/IndexadoresTipoDocumento';

import IndexacaoImagens from '../pages/IndexacaoImagens';
import EsteiraConfig from '../pages/EsteiraConfig';
import Welcome from '../pages/Welcome';
import TipoImagens from '../pages/TipoImagem';

import { isAuthenticated } from '../services/auth';

import FormTipoImagem from '../pages/TipoImagem/FormTipoImagem';
import EditTipoImagem from '../pages/TipoImagem/EditTipoImagem';
import IndexadoresTipoImagem from '../pages/TipoImagem/IndexadoresTipoImagem';

import ListEsteiraConfig from '../pages/EsteiraConfig/ListEsteiraConfig';
import FormEsteiraConfig from '../pages/EsteiraConfig/FormEsteiraConfig';
import EtapasConfig from '../pages/EsteiraConfig/EtapasConfig';

import Relatorios from '../pages/Relatorios';

import CheckTipo from '../pages/CheckTipo';
import CreateCheckTipo from '../pages/CheckTipo/CreateCheckTipo';
import AuditoriaItemConfig from '../pages/CheckTipo/AuditoriaItemConfig';
import EditCheckTipo from '../pages/CheckTipo/EditCheckTipo';
import TituloConfig from '../pages/CheckTipo/TituloConfig';
import ItemConfig from '../pages/CheckTipo/ItemConfig';
import EditItemConfig from '../pages/CheckTipo/EditItemConfig';

import EditFila from '../pages/EsteiraConfig/EditFila';

import Pesquisa from '../pages/Pesquisa';
import PesquisaAvancada from '../pages/PesquisaAvancada';
import Analise from '../pages/Analise';

import Esteira from '../pages/Esteira';

import MenuList from '../pages/Menu';
import EditMenu from '../pages/Menu/EditMenu';
import CreateMenu from '../pages/Menu/CreateMenu';

import Configuracoes from '../pages/Configuracoes';

import Auditoria from '../pages/Auditoria';

import Ops from '../pages/Ops';
import WaitingFlow from '../pages/WaitingFlow';

import GruposEmpresariais from 'pages/GruposEmpresariais';
import FormGrupoEmpresarial from 'pages/GruposEmpresariais/FormGrupoEmpresarial';

import Modalidades from 'pages/Modalidades';
import FormModalidade from 'pages/Modalidades/FormModalidade';
import TableEmpresasModalidade from 'pages/Modalidades/TableEmpresasModalidade';
import IndexadoresModalidade from 'pages/Modalidades/TableEmpresasModalidade/IndexadoresModalidade';

import UnFormExample from 'pages/UnFormExample';

import Relacionamento from 'pages/Relacionamento';
import FormRelacionamento from 'pages/Relacionamento/FormRelacionamento';
import EditRelacionamento from 'pages/Relacionamento/EditRelacionamento';

import ChangePassword from 'pages/ChangePassword';

import Unidades from 'pages/Unidades';
import FormUnidade from 'pages/Unidades/FormUnidade';

import Tarefas from 'pages/Tarefas';
import FormTarefas from 'pages/Tarefas/FormTarefa';

import MailBox from 'pages/MailBox';
import Template from 'pages/Template';
import Editor from 'pages/Template/Editor';
import FormTemplate from 'pages/Template/FormTemplate';

import Agenda from 'pages/Agenda';

import ConfigExternalDashboard from 'pages/ConfigExternalDashboard';
import ConfigFormExternalDashboard from 'pages/ConfigExternalDashboard/Form';

import AcessosRPA from 'pages/AcessosRPA';
import FormAcessoRPA from 'pages/AcessosRPA/FormAcessoRPA';
import BulkImport from 'pages/BulkImport';
import { ConfiguracaoRedirecenionamento } from 'pages/TipoDocumentos/ConfiguracaoRedirecionamento';
import FormularioPublico from 'pages/Formulario/PublicForm';
import OnboardingPublicForm from 'pages/Onboarding';
import OnboardingPrivateForm from 'pages/Onboarding/private';
import OnboardingAdministration from 'pages/Onboarding/Administration';
import FormAdmOnboarding from 'pages/Onboarding/Administration/Form';
import ClassificacaoManual from 'pages/ClassificacaoManual';
import IndexacaoTipoImagem from 'pages/IndexacaoTipoImagem';
import Realm from 'pages/Login/realm';
import { usaKeycloak } from 'utils/utils';

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: '/' }} />
      )
    }
  />
);

const Routes = () => (
  <ConnectedRouter history={history}>
    <Switch>
      {usaKeycloak()
        ? <Route exact path="/" component={Realm} />
        : <Route exact path="/" component={Login} />
      }
      {usaKeycloak()
        ? <Route exact path="/login" component={LoginV2} />
        : null
      }
      <Route exact path="/callback" component={Callback} />
      <Route exact path="/twofactor" component={TwoFactor} />
      <Route path="/auth/:token" component={LoginWithToken} />

      <Route
        exact
        path="/changepassword/:user&:org&:token"
        component={ChangePassword}
      />

      <PrivateRoute exact path="/waiting-flow/:doc" component={WaitingFlow} />
      <PrivateRoute exact path="/ops" component={Ops} />
      <PrivateRoute exact path="/inicio" component={Welcome} />

      <Route exact path="/configuracao/menu" component={MenuList} />
      <Route exact path="/configuracao/menu/edit" component={EditMenu} />
      <Route exact path="/configuracao/menu/novo" component={CreateMenu} />

      <Route
        exact
        path="/configuracao/tiposdocumento"
        component={TipoDocumentos}
      />

      <Route
        exact
        path="/configuracao/tiposdocumento/novo"
        component={CreateTipoDocumento}
      />

      <Route exact path="/importacao" component={Importacao} />

      <Route exact path="/exportacao" component={Exportacao} />

      <Route exact path="/exportacao/unic" component={ExportacaoUNIC} />

      <Route exact path="/dashboard" component={Dashboard} />

      <Route
        exact
        path="/meus-arquivos"
        component={AsynchronousResultsExecution}
      />

      <Route exact path="/cargas" component={TableCarga} />

      <Route exact path="/cargas/gerenciar" component={FormCarga} />

      <Route exact path="/rpa" component={TableRPA} />

      <Route exact path="/rpa/gerenciar" component={FormRPA} />

      <Route exact path="/layouts" component={TableLayout} />

      <Route exact path="/layout/novo" component={FormLayout} />

      <Route exact path="/layout/edit" component={EditFormLayout} />

      <Route exact path="/grupoemail" component={GrupoEmails} />

      <Route exact path="/grupoemail/novo" component={GrupoDeEmail} />

      <Route exact path="/grupoemail/edit" component={EditGrupoDeEmail} />

      <Route
        exact
        path="/checklist/preenchimento"
        component={CheckTipoPreenchimento}
      />

      <Route exact path="/formulario" component={Formularios} />
      <Route exact path="/formulario/novo" component={FormFormulario} />
      <Route exact path="/formulario/edit" component={EditFormulario} />
      <Route path="/formulario-publico" component={FormularioPublico} />

      <Route exact path="/configuracao/definicaosla" component={DefinicaoSLA} />

      <Route
        exact
        path="/configuracao/tiposdocumento/edit"
        component={EditTipoDocumento}
      />
      <Route
        exact
        path="/configuracao/tiposdocumento/indexadores"
        component={IndexadoresTipoDocumento}
      />

      <Route
        exact
        path="/configuracao/tiposdocumento/redirecionamentos"
        component={ConfiguracaoRedirecenionamento}
      />

      <PrivateRoute exact path="/administracao/empresas" component={Empresas} />
      <PrivateRoute
        exact
        path="/administracao/empresas/novo"
        component={FormEmpresas}
      />
      <Route
        exact
        path="/administracao/empresas/edit"
        component={EditEmpresas}
      />

      <PrivateRoute
        exact
        path="/administracao/empresas/novo"
        component={FormEmpresas}
      />
      <Route
        exact
        path="/administracao/empresas/edit"
        component={EditEmpresas}
      />

      <PrivateRoute
        exact
        path="/administracao/departamentos"
        component={Departamentos}
      />
      <PrivateRoute
        exact
        path="/administracao/departamentos/novo"
        component={FormDepartamento}
      />
      <PrivateRoute
        exact
        path="/administracao/departamentos/edit"
        component={EditDepartamento}
      />

      <PrivateRoute
        exact
        path="/administracao/tipodepartamento"
        component={TipoDepartamento}
      />
      <PrivateRoute
        exact
        path="/administracao/tipodepartamento/novo"
        component={FormTipoDepartamento}
      />
      <PrivateRoute
        exact
        path="/administracao/tipodepartamento/edit"
        component={EditTipoDepartamento}
      />

      <PrivateRoute
        exact
        path="/administracao/categorias"
        component={Categorias}
      />
      <PrivateRoute
        exact
        path="/administracao/categorias/novo"
        component={FormCategoria}
      />
      <PrivateRoute
        exact
        path="/administracao/categorias/edit"
        component={EditCategoria}
      />

      <PrivateRoute
        exact
        path="/administracao/departamentos/escalonamento"
        component={DepartamentoEscalonamento}
      />

      <PrivateRoute exact path="/configuracao/uf" component={UF} />
      <PrivateRoute exact path="/configuracao/uf/novo" component={FormUF} />
      <PrivateRoute exact path="/configuracao/uf/edit" component={EditUF} />

      <PrivateRoute exact path="/configuracao/ocorrencias" component={Ocorrencias} />
      <PrivateRoute exact path="/configuracao/ocorrencia/nova" component={FormOcorrencia} />
      <PrivateRoute exact path="/configuracao/ocorrencia/edit" component={EditOcorrencia} />

      <PrivateRoute
        exact
        path="/configuracao/instancia"
        component={Instancia}
      />
      <PrivateRoute
        exact
        path="/configuracao/instancia/novo"
        component={FormInstancia}
      />
      <PrivateRoute
        exact
        path="/configuracao/instancia/edit"
        component={EditInstancia}
      />

      <PrivateRoute exact path="/configuracao/acao" component={Acao} />
      <PrivateRoute exact path="/configuracao/acao/novo" component={FormAcao} />
      <PrivateRoute exact path="/configuracao/acao/edit" component={EditAcao} />

      <PrivateRoute
        exact
        path="/configuracao/assessoria"
        component={Assessoria}
      />
      <PrivateRoute
        exact
        path="/configuracao/assessoria/novo"
        component={FormAssessoria}
      />
      <PrivateRoute
        exact
        path="/configuracao/assessoria/edit"
        component={EditAssessoria}
      />

      <PrivateRoute
        exact
        path="/operacao/indexacaoimagens"
        component={IndexacaoImagens}
      />
      <PrivateRoute
        exact
        path="/operacao/esteiraconfig"
        component={EsteiraConfig}
      />

      <PrivateRoute exact path="/operacao/esteira" component={Esteira} />

      <PrivateRoute exact path="/operacao/relatorios" component={Relatorios} />

      <PrivateRoute
        exact
        path="/configuracao/tipoimagens"
        component={TipoImagens}
      />
      <PrivateRoute
        exact
        path="/configuracao/tipoimagens/create"
        component={FormTipoImagem}
      />
      <PrivateRoute
        exact
        path="/configuracao/tipoimagens/edit"
        component={EditTipoImagem}
      />
      <PrivateRoute
        exact
        path="/configuracao/tipoimagem/indexadores"
        component={IndexadoresTipoImagem}
      />

      <PrivateRoute
        exact
        path="/operacao/esteiraconfig/"
        component={ListEsteiraConfig}
      />
      <PrivateRoute
        exact
        path="/operacao/esteiraconfig/create"
        component={FormEsteiraConfig}
      />

      <PrivateRoute
        exact
        path="/operacao/esteiraconfig/editfila"
        component={EditFila}
      />

      <PrivateRoute exact path="/operacao/checktipo" component={CheckTipo} />
      <PrivateRoute
        exact
        path="/operacao/checktipo/createchecktipo"
        component={CreateCheckTipo}
      />

      <PrivateRoute
        exact
        path="/operacao/checktipo/auditoria"
        component={AuditoriaItemConfig}
      />

      <PrivateRoute
        exact
        path="/operacao/checktipo/editchecktipo"
        component={EditCheckTipo}
      />
      <PrivateRoute
        exact
        path="/operacao/checktipo/titulo"
        component={TituloConfig}
      />
      <PrivateRoute
        exact
        path="/operacao/checktipo/item"
        component={ItemConfig}
      />
      <PrivateRoute
        exact
        path="/operacao/checktipo/item/edit"
        component={EditItemConfig}
      />

      <Route exact path="/operacao/checktipo" component={CheckTipo} />

      <Route
        exact
        path="/param/assinatura-eletronica"
        component={FormParamAssinaturaEletronic}
      />

      <Route
        exact
        path="/operacao/esteiraconfig/etapas"
        component={EtapasConfig}
      />
      <PrivateRoute exact path="/operacao/analise" component={Analise} />

      <Route exact path="/documentos/pesquisa" component={Pesquisa} />
      <Route
        exact
        path="/documentos/pesquisa/avancada"
        component={PesquisaAvancada}
      />

      <Route exact path="/configuracoes" component={Configuracoes} />

      <PrivateRoute
        exact
        path="/administracao/auditoria"
        component={Auditoria}
      />

      <PrivateRoute
        exact
        path="/administracao/gruposempresariais"
        component={GruposEmpresariais}
      />

      <PrivateRoute
        exact
        path="/administracao/gruposempresariais/edit"
        component={FormGrupoEmpresarial}
      />
      <PrivateRoute
        exact
        path="/administracao/gruposempresariais/create"
        component={FormGrupoEmpresarial}
      />
      <PrivateRoute exact path="/unform" component={UnFormExample} />

      <PrivateRoute
        exact
        path="/administracao/relacionamentos"
        component={Relacionamento}
      />

      <PrivateRoute
        exact
        path="/administracao/relacionamento/novo"
        component={FormRelacionamento}
      />

      <PrivateRoute
        exact
        path="/administracao/relacionamentos/edit"
        component={EditRelacionamento}
      />

      <PrivateRoute
        exact
        path="/configuracao/modalidades"
        component={Modalidades}
      />

      <PrivateRoute
        exact
        path="/configuracao/modalidades/create"
        component={FormModalidade}
      />

      <PrivateRoute
        exact
        path="/configuracao/modalidades/edit"
        component={FormModalidade}
      />

      <PrivateRoute
        exact
        path="/configuracao/modalidades/imagens"
        component={TableEmpresasModalidade}
      />

      <PrivateRoute
        exact
        path="/configuracao/modalidades/imagens/indexadores"
        component={IndexadoresModalidade}
      />

      <PrivateRoute exact path="/configuracao/unidades" component={Unidades} />

      <PrivateRoute
        exact
        path="/configuracao/unidades/create"
        component={FormUnidade}
      />

      <PrivateRoute
        exact
        path="/configuracao/unidades/edit"
        component={FormUnidade}
      />

      <PrivateRoute exact path="/administracao/tarefas" component={Tarefas} />

      <PrivateRoute
        exact
        path="/administracao/tarefas/create"
        component={FormTarefas}
      />

      <PrivateRoute
        exact
        path="/administracao/tarefas/edit"
        component={FormTarefas}
      />

      <PrivateRoute exact path="/mensagens" component={MailBox} />
      <PrivateRoute exact path="/template" component={Template} />
      <PrivateRoute exact path="/template/editor" component={Editor} />
      <PrivateRoute exact path="/template/edit" component={FormTemplate} />
      <PrivateRoute exact path="/agenda" component={Agenda} />
      <PrivateRoute exact path="/twofactor" component={TwoFactor} />

      <PrivateRoute
        exact
        path="/configuracao/external-dashboard"
        component={ConfigExternalDashboard}
      />

      <PrivateRoute
        exact
        path="/configuracao/external-dashboard/gerenciar"
        component={ConfigFormExternalDashboard}
      />

      <PrivateRoute
        exact
        path="/configuracao/acessos-rpa"
        component={AcessosRPA}
      />

      <PrivateRoute
        exact
        path="/configuracao/acessos-rpa/novo"
        component={FormAcessoRPA}
      />

      <PrivateRoute
        exact
        path="/configuracao/acessos-rpa/editar"
        component={FormAcessoRPA}
      />

      <PrivateRoute exact path="/bulk-import" component={BulkImport} />

      {/* Onboarding */}
      <Route
        exact
        path="/onboarding/:organization/:id"
        component={OnboardingPublicForm}
      />

      <Route
        exact
        path="/onboarding/private/:organization/:id"
        component={OnboardingPrivateForm}
      />

      <PrivateRoute
        exact
        path="/configuracao/onboarding"
        component={OnboardingAdministration}
      />
      <PrivateRoute
        exact
        path="/configuracao/onboarding/novo"
        component={FormAdmOnboarding}
      />
      <PrivateRoute
        exact
        path="/configuracao/onboarding/editar"
        component={FormAdmOnboarding}
      />
      <PrivateRoute
        exact
        path="/classificacao"
        component={ClassificacaoManual}
      />
      <PrivateRoute
        exact
        path="/indexacaotpimg"
        component={IndexacaoTipoImagem}
      />
    </Switch>
  </ConnectedRouter>
);

export default Routes;
