import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Row, Col } from 'react-bootstrap';
import * as pdfjsLib from 'pdfjs-dist';
import { toastr } from 'react-redux-toastr';
import jsPDF from 'jspdf';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import { getPresignedUrl } from 'services/presignedUrl';

import { InfoIcon } from 'components/Icons';
import Content from 'components/Content';
import Viewer from 'components/SearchViewer/Viewer';
import IndexadoresClassificaoManual from './IndexadoresTpImagemManual';
import GaleriaIndexacaoManual from './GaleriaIndexacaoManual';

import { Creators as UnfilledTpImgActions } from 'store/ducks/unfilledtpimg';
import { Creators as TiposImagemActions } from 'store/ducks/tipoimagem';
import { Buttons, ContainerMessage, ContainerMessageCount } from './style';

export default function IndexacaoTipoImagem() {
  const dispatch = useDispatch();

  const [imagesToProcess, setImagesToProcess] = useState([]);
  const [currentFile, setCurrentFile] = useState();
  const [pdfPages, setPdfPages] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);
  const [loadingSave, setLoadingSave] = useState(false);

  const { document: documento, loading: loadingDocument } = useSelector(
    (state) => state.unfilledtpimg
  );

  useEffect(() => {
    if (documento && documento.idTipoDocumento) {
      dispatch(
        TiposImagemActions.listByTipoDocumentoRequest(documento.idTipoDocumento)
      );
    }
  }, [dispatch, documento]);

  const handleUpdateCurrentIndexadores = ({ target }) => {
    const { value, name } = target;

    let indexadores = currentFile.indexadores;

    indexadores = indexadores.map((indexador) => {
      if (indexador.nome === name) {
        indexador.valor = value;
      }

      return indexador;
    });

    setCurrentFile({ ...currentFile, indexadores });
  }

  const handleUpdateClassification = async () => {
    setLoadingSave(true);

    let file = {
      filepath: currentFile.original,
      indexadores: currentFile.indexadores,
    };

    dispatch(
      UnfilledTpImgActions.updateIndexadoresTpImgRequest({
        documento: documento._id,
        file,
      })
    );

    const newImagesToProcess = imagesToProcess.filter(
      (image) => image.filepath !== currentFile.original
    );

    setImagesToProcess(newImagesToProcess);

    if (newImagesToProcess.length === 0) {
      setCurrentFile(null);
      dispatch(UnfilledTpImgActions.getNextJobRequest({}));

    } else {
      const firstDocument = newImagesToProcess[0].filepath;
      const indexadores = newImagesToProcess[0].indexadores;
      const tipoImagem = newImagesToProcess[0].tipoImagem;

      const presignedPdf = await getPresignedUrl(firstDocument);
      setCurrentFile({ presignedPdf, original: firstDocument, indexadores, tipoImagem });
    }

    setLoadingSave(false);
  };

  useEffect(() => {
    async function getFirstPresignedUrl() {
      if (!documento) return;

      if (documento.type) {
        toastr.success('', documento.message);
        return;
      }

      setImagesToProcess(documento.imagens);

      const firstDocument = documento.imagens[0].filepath;
      const indexadores = documento.imagens[0].indexadores;
      const tipoImagem = documento.imagens[0].tipoImagem;

      const presignedPdf = await getPresignedUrl(firstDocument);
      setCurrentFile({ presignedPdf, original: firstDocument, indexadores, tipoImagem });
    }

    getFirstPresignedUrl();
  }, [documento]);

  useEffect(() => {
    dispatch(UnfilledTpImgActions.getNextJobRequest({}));
  }, []);

  return (
    <Content>
      <Row>
        <Col md={documento && documento.type ? 12 : 9} className="painel">
          <div className="painel-content">
            {loadingDocument ? (
              <ContainerMessage>
                <div>
                  <InfoIcon />
                </div>
                <h4>Carregando...</h4>
              </ContainerMessage>
            ) : (
              <>
                {documento && documento.type && (
                  <ContainerMessage>
                    <div>
                      <InfoIcon />
                    </div>
                    <h4>Não há novos documentos para indexar</h4>
                  </ContainerMessage>
                )}

                {pdfPages.length > 0 ? (
                  <GaleriaIndexacaoManual
                    images={pdfPages}
                    selectedImages={selectedImages}
                    setSelectedImages={setSelectedImages}
                  />
                ) : (
                  <>
                    {currentFile && (
                      <Viewer imagem={currentFile.presignedPdf} />
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </Col>
        {documento && !documento.type && (
          <Col md="3" className="painel">
            <div className="painel-content">
              {loadingDocument ? (
                <div>Carregando...</div>
              ) : (
                <ContainerMessageCount>
                  {documento && (
                    <>
                      {!loadingSave && (
                        <IndexadoresClassificaoManual
                          document={documento}
                          currentFile={currentFile}
                          handleUpdateClassification={handleUpdateClassification}
                          handleUpdateCurrentIndexadores={handleUpdateCurrentIndexadores}
                        />
                      )}

                      {documento.messageCount !== undefined && (
                        <p style={{ marginTop: '1rem', color: 'gray' }}>
                          Existem {documento.messageCount} documentos restantes na fila.
                        </p>
                      )}
                    </>
                  )}
                </ContainerMessageCount>

              )}
            </div>
          </Col>
        )}
      </Row>
    </Content>
  );
}
