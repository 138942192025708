import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  TableContainer,
  TableFooter,
  TablePagination,
  Tooltip,
  Typography,
  CircularProgress,
  Button,
  Paper,
  Checkbox,
} from '@material-ui/core';

import Countdown from 'components/CountdownTimer';
import DiffDates from 'components/DiffTwoIsoStrings';
import DefaultPanel from 'components/DefaultPanel';

import { Modal, Row } from 'react-bootstrap';
import { LabelSwapper } from '../../../../components/LabelSwapper';
import ActionMenu from './ActionMenu';

import ModalBulkTaskMove from 'components/ModalBulkTaskMove';
import CustomTableSortLabel from 'components/CustomTableSortLabel';
import LoadingTable from 'components/LoadingTable';
import StyledTableRow from 'components/StyledTableRow';
import ButtonSquare from 'components/ButtonSquare';
import Chronometer from '../../../../components/Chronometer';
import ptBR from 'date-fns/locale/pt-BR';
import { isValid, format, parseISO } from 'date-fns';

import { Creators as AnaliseActions } from 'store/ducks/analise';
import { Creators as DocumentoActions } from 'store/ducks/documentos';

import './styles.css';
import { DebouncedAutoComplete } from 'components/DebouncedAutoComplete';
import { Creators } from 'store/ducks/usuarios';
import { SensibleFlag } from 'components/SensibleFlag';
import CountdownTimer from "components/CountdownTimer";

function getTimeLastAnalist(doc) {
  const { analistasDoDocumento, status, analista } = doc;
  if (!analistasDoDocumento || !status || !analista) return null;
  const last = analistasDoDocumento[analistasDoDocumento.length - 1];
  return last?.date;
}

function getNameFila(fluxo, listaFilas = []) {
  try {
    const { list } = listaFilas;
    const fluxoKey = fluxo.split(':').shift();
    const filaName = list.find(e => e.fila === fluxoKey);
    return filaName.nome;
  } catch {
    return '';
  }
}

export default function SearchResult({ fila, collapse, setCollapse }) {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [selecionadas, setSelecionadas] = useState([]);
  const [allSelecionadas, setAllSelecionadas] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const { organization } = useSelector(state => state.usuarios.auth);
  const [rowsPerPageOptions, setRowsPerPageOptions] = useState([10, 25, 50, 100]);

  const limite_de_campos_exibidos = 3;

  const [showBulkModal, setShowBulkModal] = useState(false);

  const openIndexador = () => {
    handleCloseActionMenu();
    setShowBulkModal(true);
  };

  const handleStay = () => {
    setShowBulkModal(false);
  };

  const {
    byEtapas: documentos,
    etapas,
    loading,
    limit = 10,
    page = 1,
    total,
    sort = 'documentoAnalise.0.indexadores.0.valor',
  } = useSelector(state => state.documentos);
  const { loading: tpDocLoading } = useSelector(state => state.tipoDocumentos);
  const { analysts } = useSelector(state => state.usuarios);

  const listaFilas = useSelector(state => state.filas);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [actionMenuAnchor, setActionMenuAnchor] = useState(null);
  const [tenant, setTenant] = useState('');

  const { loading: loadingAnalise } = useSelector(state => state.analise);
  const { sla, values, etapaFinal } = useSelector(state => state.documentos);
  const {
    auth: { id: idUsuario },
  } = useSelector(state => state.usuarios);

  const {
    access: { empresas, servicos },
  } = useSelector(state => state.permissoes);

  const [request, setRequest] = useState({
    empresas,
    limit: limit > 100 ? 10 : limit,
    page,
    sort,
  });

  function handleChangeRowsPerPage({ target, mantemSelecionadas = false }) {
    const { value: limit } = target;

    if (!mantemSelecionadas) {
      setAllSelecionadas(false);
      setSelecionadas([]);
    }

    setRequest({ ...request, limit, page: 1 });
    dispatch(
      DocumentoActions.listRequestByEtapas({
        ...request,
        etapas,
        fila,
        limit,
        page: 1,
        sla,
        values,
        fields: 'documentoAnalise.indexadores,usuarioAcao,etapas,ultimaEtapa,dtUltimaEtapa,sla',
      })
    );
  }

  const handleCheckAll = ({ target }) => {
    const { checked: valor } = target;

    setAllSelecionadas(valor);

    if (valor) {
      setRequest({ ...request, page: 1 });

      // se os registros visualizados não estão na sua totalidade, faz a request, caso contrário já estarão todos listados na tela, basta marcar todos nessa situação
      if (request.limit < total) {
        const LIMIT_CAMUNDA = total;
        setRowsPerPageOptions([LIMIT_CAMUNDA]);
        handleChangeRowsPerPage({ target: { value: LIMIT_CAMUNDA }, mantemSelecionadas: true });
      }

    } else {
      setRowsPerPageOptions([10, 25, 50, 100]);
      setRequest({ ...request, limit: 10, page: 1 });
      setSelecionadas([]);
    }
  };

  useEffect(() => {
    if (allSelecionadas && !loading) {
      setSelecionadas(documentos?.map(doc => doc.id));
    }

  }, [
    allSelecionadas,
    documentos,
    loading,
    request,
    total,
    setRequest,
    dispatch,
  ]);

  useEffect(() => {
    if (allSelecionadas && selecionadas?.length > 0 && total > documentos?.length) {
      setRowsPerPageOptions([10, 25, 50, 100]);
      setRequest({ ...request, limit: 10, page: 1 });
      setAllSelecionadas(false);
      setSelecionadas([]);
    }
  }, [selecionadas]);

  const handleCheck = ({ target }, id) => {
    const { checked } = target;

    if (checked) setSelecionadas([...selecionadas, id]);
    else setSelecionadas([...selecionadas.filter(sel => sel !== id)]);
  };

  const [userActions, setUserActions] = useState({});

  const toggleModal = () => setIsModalOpen(prev => !prev);

  const sendOneToSearchPage = id => {
    dispatch(
      DocumentoActions.sendToSearchPageRequest(
        [id],
        {
          limit: 10,
          page: 1,
          sort: '-createdAt',
          searchText: '',
        },
        false,
        true
      )
    );
  };

  function handleChangePage(e, newPage) {
    setRequest({ ...request, page: newPage + 1 });
    dispatch(
      DocumentoActions.listRequestByEtapas({
        ...request,
        etapas,
        fila,
        page: newPage + 1,
        sla,
        values,
        fields: 'documentoAnalise.indexadores,usuarioAcao,etapas,ultimaEtapa,dtUltimaEtapa,sla',
      })
    );
  }

  function handleChangeSort(sort) {
    setRequest({ ...request, sort });
    dispatch(
      DocumentoActions.listRequestByEtapas({
        ...request,
        fila,
        etapas,
        sort,
        sla,
        values,
        fields: 'documentoAnalise.indexadores,usuarioAcao,etapas,ultimaEtapa,dtUltimaEtapa,sla',
      })
    );
  }

  function handleClick(_id, taskId, processInstanceId, type, etapa, fluxo) {
    const nomeFila = getNameFila(fluxo, listaFilas);
    dispatch(AnaliseActions.getRequest(_id, taskId, processInstanceId, type, etapa, nomeFila, fluxo));
    dispatch(DocumentoActions.getDocumentoRequest(_id));
  }

  function handleUnassign(taskId, processInstanceId, type, doc) {
    const { documento: idDocumento, name: etapa, processDefinitionId: fluxo } = doc;
    const nomeFila = getNameFila(fluxo, listaFilas);
    dispatch(AnaliseActions.unassignRequest(taskId, processInstanceId, type, idDocumento, etapa, nomeFila, fluxo));
  }

  function handleOpenActionMenu(e) {
    setActionMenuAnchor(e.currentTarget);
  }

  function handleCloseActionMenu() {
    setActionMenuAnchor(null);
  }

  const formatISOString = isoDate => {
    const parsedDate = parseISO(isoDate);
    const isValidDate = isValid(parsedDate);

    const formattedDate = isValidDate ? format(parsedDate, 'dd/MM/yyyy HH:mm', { locale: ptBR }) : '-';

    return formattedDate;
  };

  const handleUnasignAllDocumentsFromUser = document => {
    const userId = document.assignee;

    dispatch(AnaliseActions.batchUnassignFromUserRequest(userId));
  };

  const colorizeDueDate = vencimento => {
    //preciso realizar a verificação se a data+hora atual é maior que a data+hora de vencimento
    const date = new Date(vencimento);
    const today = new Date();

    if (date < today) {
      return 'red';
    }

    return 'green';
  };

  useEffect(() => {
    let find = servicos.find(item => item.route === pathname);
    if (find !== undefined) {
      setUserActions(find);
    }
  }, [pathname, servicos]);

  useEffect(() => {
    setTenant(organization);
  }, [organization]);

  const checkSelecionadas = (doc) => {
    return selecionadas.includes(doc.id)
  }

  const color = '#fef3c5';
  const colorLight = color;
  const colorDark = color;

  return (
    <Row>
      <Modal show={isModalOpen} onHide={() => toggleModal()} size="xl">
        <Modal.Header>
          <Modal.Title>
            {selectedDocument?.type === 'UNASSIGN' ? 'Desvincular tarefas do usuário' : 'Vincular tarefa a um usuário'}
          </Modal.Title>
        </Modal.Header>

        {selectedDocument?.type === 'ASSIGN' && (
          <Modal.Body>
            <DebouncedAutoComplete
              name="users"
              onChange={value => {
                setSelectedUserId(value?._id);
              }}
              options={() => {
                return analysts?.results ?? [];
              }}
              autofocus
              loading={analysts.loading}
              getOptionLabel={option => option.nome}
              onFirstMount={() => dispatch(Creators.searchByNameRequest(''))}
              onDebounce={value => {
                dispatch(Creators.searchByNameRequest(value));
              }}
            />
          </Modal.Body>
        )}

        <Modal.Footer>
          <div
            style={{
              display: 'flex',
              gap: '0 1rem',
            }}
          >
            <Button onClick={toggleModal}>Cancelar</Button>

            {selectedDocument?.type === 'UNASSIGN' && (
              <div
                style={{
                  display: 'flex',
                  gap: '0 1rem',
                }}
              >
                <Button
                  onClick={() => {
                    handleUnassign(
                      selectedDocument.id,
                      selectedDocument.processInstanceId,
                      'analise',
                      selectedDocument
                    );
                    toggleModal();
                  }}
                  disabled={loadingAnalise}
                  color="primary"
                  variant="contained"
                >
                  <LabelSwapper text={'Desvincular apenas essa análise'} parent={'SearchResult'} name={'unassing'} />
                </Button>

                <Button
                  onClick={() => {
                    handleUnasignAllDocumentsFromUser(selectedDocument);
                    toggleModal();
                  }}
                  color="secondary"
                  variant="contained"
                  disabled={loadingAnalise}
                >
                  <LabelSwapper text={'Desvincular todas as análise'} parent={'SearchResult'} name={'unassing_all'} />
                </Button>
              </div>
            )}

            {selectedDocument?.type === 'ASSIGN' && (
              <div>
                <Button
                  onClick={() => {
                    dispatch(
                      AnaliseActions.assignTaskToUserRequest({
                        taskId: selectedDocument.id,
                        userId: selectedUserId,
                        documentId: selectedDocument.documento,
                        search: values,
                      })
                    );

                    toggleModal();
                  }}
                  disabled={!selectedUserId}
                  color="primary"
                  variant="contained"
                >
                  Vincular ao usuário
                </Button>
              </div>
            )}
          </div>
        </Modal.Footer>
      </Modal>
      <DefaultPanel
        size={12}
        titleText="Análises pendentes"
        collapse={collapse}
        setCollapse={setCollapse}
        isCollapsible={true}
        actionMenu={ActionMenu}
        handleOpenActionMenu={handleOpenActionMenu}
      >
        <div className="esteiraSearchResult">
          {!!loadingAnalise && (
            <>
              <Typography>Carregando análises pendentes...</Typography>
              <CircularProgress size={20} />
            </>
          )}
          {!loadingAnalise && (
            <TableContainer component={Paper}>
              {(documentos && documentos.length > 0) || !!loading ? (
                <Table aria-label="collapsible table" size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Checkbox name="selecionar" color="primary " onChange={e => handleCheckAll(e)} checked={allSelecionadas} disabled={loading} />
                      </TableCell>
                      <TableCell>
                        <CustomTableSortLabel
                          currentSort={request.sort}
                          sortField="numeroCNJ"
                          onClick={handleChangeSort}
                        >
                          <LabelSwapper text={'Nº do cliente'} parent={'SearchResult'} name={'numeroCNJ'} />
                        </CustomTableSortLabel>
                      </TableCell>
                      <TableCell>
                        <CustomTableSortLabel currentSort={request.sort} sortField="name" onClick={handleChangeSort}>
                          Etapa/Fila
                        </CustomTableSortLabel>
                      </TableCell>

                      {documentos &&
                        documentos.length > 0 &&
                        documentos[0].indexadoresPesquisaDetalhada
                          .slice(0, limite_de_campos_exibidos)
                          .map(indexador => (
                            <TableCell
                              align="left"
                              style={{
                                width: indexador.nome.toUpperCase().includes('NOME') ? '250px' : '150px',
                              }}
                            >
                              <SensibleFlag indexador={indexador}>{indexador.nome}</SensibleFlag>
                            </TableCell>
                          ))}

                      <TableCell align="center">Término do prazo</TableCell>
                      <TableCell align="center">
                        <CustomTableSortLabel
                          currentSort={request.sort}
                          sortField="vencimento"
                          onClick={handleChangeSort}
                        >
                          Data de vencimento
                        </CustomTableSortLabel>
                      </TableCell>

                      <TableCell align="center">Tempo de análise</TableCell>
                      <TableCell align="center">Ações</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(!!loading || !!tpDocLoading) && <LoadingTable columns={10} rows={request.limit} />}
                    {!loading &&
                      !tpDocLoading &&
                      documentos.map((doc, index) => {
                        return (
                          <StyledTableRow
                            key={doc.id}
                            backgroundColor={doc.alerta?.length > 0 ? (index % 2 ? colorDark : colorLight) : null}
                          >
                            <TableCell align="left" style={{ width: '150px' }}>
                              <Checkbox
                                name="selecionar"
                                color="primary"
                                disabled={loading}
                                checked={checkSelecionadas(doc)}
                                onChange={e => handleCheck(e, doc.id)}
                              />
                            </TableCell>
                            <TableCell>{doc.numeroCNJ}</TableCell>
                            <TableCell>
                              {
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    flexDirection: 'column',
                                  }}
                                >
                                  {<b>{doc.name}</b>}
                                  {<span>{doc.fila}</span>}
                                </div>
                              }
                            </TableCell>
                            {doc.indexadoresPesquisaDetalhada.slice(0, limite_de_campos_exibidos).map(indexador => (
                              <TableCell
                                align="left"
                                style={{
                                  width: indexador.nome.toUpperCase().includes('NOME') ? '250px' : '150px',
                                }}
                              >
                                {indexador.valor}
                              </TableCell>
                            ))}

                            <TableCell align="center">
                              {doc?.dataParalizacaoSLA ? (
                                <DiffDates date1={doc.dataParalizacaoSLA} date2={doc.vencimento} />
                              ) : (
                                <Countdown date={doc.vencimento} />
                              )}
                            </TableCell>
                            <TableCell align="center" style={{ color: colorizeDueDate(doc?.vencimento) }}>
                              {doc?.dataParalizacaoSLA ? ' - ' : formatISOString(doc?.vencimento)}
                            </TableCell>
                            <TableCell align="left">
                              {getTimeLastAnalist(doc) && (
                                <Chronometer
                                  onTimeColor={'black'}
                                  onTime={true}
                                  measureOfTime={' '}
                                  duration={<CountdownTimer date={getTimeLastAnalist(doc)} getTimeElapsed={true} style={{ color: "black" }} />}
                                />
                              )}
                            </TableCell>
                            <TableCell align="right">
                              <div className="esteira-buttons">
                                {userActions &&
                                  userActions.actions &&
                                  userActions.actions.find(a => a.action === 'desvincular') && (
                                    <Tooltip
                                      title={
                                        !!doc.taskLock
                                          ? `Desvincular o analista ${doc.analista.nome} desta análise`
                                          : 'Sem analista vinculado a análise'
                                      }
                                      placement="top"
                                    >
                                      <span>
                                        <ButtonSquare
                                          onClick={() => {
                                            toggleModal();
                                            setSelectedDocument({
                                              ...doc,
                                              type: doc.taskLock ? 'UNASSIGN' : 'ASSIGN',
                                            });
                                          }}
                                          color={'primary'}
                                          icon={doc.taskLock ? 'person_off' : 'person_add'}
                                        />
                                      </span>
                                    </Tooltip>
                                  )}

                                {!etapaFinal && (
                                  <Tooltip
                                    title={
                                      !doc.taskLock || doc.assignee === idUsuario
                                        ? 'Ir para análise'
                                        : `Analise sendo realizada por ${doc.analista.nome}`
                                    }
                                    placement="top"
                                  >
                                    <span>
                                      <ButtonSquare
                                        onClick={() =>
                                          handleClick(
                                            doc.documento,
                                            doc.id,
                                            doc.processInstanceId,
                                            'analise',
                                            doc.name,
                                            doc.processDefinitionId
                                          )
                                        }
                                        color={!!doc.taskLock && doc.assignee !== idUsuario ? 'secondary' : 'primary'}
                                        disabled={!!doc.taskLock && doc.assignee !== idUsuario}
                                        icon="assignment"
                                      />
                                    </span>
                                  </Tooltip>
                                )}

                                <Tooltip title={'Visualizar'} placement="top">
                                  <span>
                                    <ButtonSquare onClick={() => sendOneToSearchPage(doc.documento)} icon="visibility" />
                                  </span>
                                </Tooltip>
                              </div>
                            </TableCell>
                          </StyledTableRow>
                        )
                      })}
                  </TableBody>
                  {!loading && (
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          count={total}
                          page={request.page - 1}
                          rowsPerPage={request.limit}
                          rowsPerPageOptions={rowsPerPageOptions}
                          labelRowsPerPage="Linhas por página"
                          labelDisplayedRows={({ from, to, count }) =>
                            `${from}-${to} de ${count !== -1 ? count : `mais que ${to}`}`
                          }
                          onRowsPerPageChange={handleChangeRowsPerPage}
                          onPageChange={handleChangePage}
                        />
                      </TableRow>
                    </TableFooter>
                  )}
                </Table>
              ) : (
                <span className="list__none">Não há análises pendentes a serem exibidas.</span>
              )}
            </TableContainer>
          )}
        </div>
        {actionMenuAnchor && (
          <ActionMenu
            tenant={tenant}
            documentos={documentos}
            anchor={actionMenuAnchor}
            selecionadas={selecionadas}
            openIndexador={openIndexador}
            handleClose={handleCloseActionMenu}
          />
        )}
      </DefaultPanel>

      {!loading && (<ModalBulkTaskMove
        showModal={showBulkModal}
        documentos={documentos}
        selecionadas={selecionadas}
        handleStay={handleStay}
      />)}

    </Row>
  );
}
