export const Types = {
  NEXTJOB_REQUEST: 'unfilledtpimg/NEXTJOB_REQUEST',
  NEXTJOB_SUCCESS: 'unfilledtpimg/NEXTJOB_SUCCESS',
  UPDATEINDEXADORESTPIMG_REQUEST: 'unfilledtpimg/UPDATEINDEXADORESTPIMG_REQUEST',
  UPDATEINDEXADORESTPIMG_SUCCESS: 'unfilledtpimg/UPDATEINDEXADORESTPIMG_SUCCESS',
};

/* Reducer */

const INITIAL_STATE = {
  loading: false,
  loadingClassification: false,
  document: null,
};

export default function unfilledtpimg(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.NEXTJOB_REQUEST:
      return { ...state, loading: true, document: null };

    case Types.NEXTJOB_SUCCESS:
      return { ...state, document: action.payload, loading: false };

    case Types.UPDATEINDEXADORESTPIMG_REQUEST:
      return { ...state, loadingClassification: true };

    case Types.UPDATEINDEXADORESTPIMG_SUCCESS:
      return { ...state, loadingClassification: false };

    default:
      return state;
  }
}

export const Creators = {
  getNextJobRequest: () => ({
    type: Types.NEXTJOB_REQUEST,
    payload: {},
  }),

  getNextJobSuccess: (payload) => ({
    type: Types.NEXTJOB_SUCCESS,
    payload,
  }),

  updateIndexadoresTpImgRequest: (payload) => ({
    type: Types.UPDATEINDEXADORESTPIMG_REQUEST,
    payload,
  }),

  updateIndexadoresTpImgSuccess: (payload) => ({
    type: Types.UPDATEINDEXADORESTPIMG_SUCCESS,
    payload,
  }),
};
