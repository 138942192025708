import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { toastr } from 'react-redux-toastr';

import { Row, Col, Form } from 'react-bootstrap';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';

import SearchViewer from '../../components/SearchViewer';

import Indexadores from './components/Indexadores';
import Destaque from './components/Destaque';
import Historico from './components/Historico';
import TabPanel from '../../components/TabPanel';
import Comentarios from './components/Comentarios';

import useForm from 'react-hook-form';

import { Creators as CheckListDocumentoActions } from '../../store/ducks/checklistdocumento';
import { Creators as AnaliseActions } from '../../store/ducks/analise';
import { Creators as DocumentosActions } from '../../store/ducks/documentos';
import { Creators as GrupoAcessoActions } from '../../store/ducks/grupoacesso';
import { Creators as AuditoriaActions } from '../../store/ducks/auditoria';
import { Creators as EmailActions } from '../../store/ducks/mail';

import './styles.css';
import { Mails } from 'components/Mails';
import { TooltipEmail } from 'components/TooltipEmail';
import {
  HistoryIcon,
  MailUnreadedIcon,
  KeyboardArrowRightRoundedIcon,
  KeyboardArrowLeftRoundedIcon,
} from 'components/Icons';
import Content from 'components/Content';
import Formalizacao from 'pages/CheckTipoPreenchimento';
import FormalizacaoButtons from 'pages/CheckTipoPreenchimento/buttons';
import { AlignCenter } from 'react-feather';
import { set } from 'lodash';
import Utils from 'utils/utils';
import api from 'services/api';
import { getToken } from 'services/auth';

const TAB_HISTORICO = 0;
const TAB_EMAILS = 1;

export default function Analise(params) {
  const { register, handleSubmit } = useForm();
  const dispatch = useDispatch();

  let { documento, taskId } = params?.location?.state;

  const [form, setForm] = useState(documento);

  const xkeyorg = useSelector((state) => state.usuarios.auth.organization);
  const checkTipos = useSelector((state) => state.checkTipos.list[0]);
  const { loading: loadingFormalizacao } = useSelector((state) => state.checklistdocumento);
  const { list: comentarios } = useSelector((state) => state.comentarios);
  const { byEtapas, documento: selectedDoc } = useSelector(
    (state) => state.documentos
  );

  const configuracoes = useSelector(state => state.configuracoes.configuracoes);

  const [indexadores, setIndexadores] = useState([]);
  const [value, setValue] = useState(0);
  const [leftSize, setLeftSize] = useState(12);
  const [loading, setLoading] = useState(false);
  const [afterFormalizacaoLoading, setAfterFormalizacaoLoading] = useState(false);
  const [analise, setAnalise] = useState(
    documento.analise && documento.analise.data.length > 0
      ? documento.analise.data
      : checkTipos
        ? checkTipos.titulos
        : []
  );
  const [currentEtapa, setCurrentEtapa] = useState(null);
  const [itensModifyChecklist, setItensModifyChecklist] = useState([]);

  const [chargeHistory, setChargeHistory] = useState(0);

  useEffect(() => {
    console.log("Mudou o estado")
    setAfterFormalizacaoLoading(loadingFormalizacao);
  }, [loadingFormalizacao])

  useEffect(() => {
    loadGrupoAcesso();
    loadEmails();
  }, []);

  useEffect(() => {
    byEtapas.length > 0 && setCurrentEtapa(byEtapas.find(etapa => etapa.numeroCNJ === documento.numeroCNJ).taskDefinitionKey);
  }, [byEtapas]);

  useEffect(() => {
    const params = {
      idDocumento: documento._id,
    };
    if (configuracoes?.showExtendedAnalysis === false) {
      setLeftSize(6);
    }
    dispatch(AuditoriaActions.listIdentificadorRequest(params));
  }, [dispatch, chargeHistory]);

  const loadEmails = async () => {
    if (documento) dispatch(EmailActions.listRequest(documento._id));
  };

  const copyItensFormalizacao = () => {
    const copyToClipboard = itensModifyChecklist
      .map((item) => {
        const allMotivos = item.motivo.map((motivo) => {
          let defaultMotivo = `${item.nome} - ${motivo}`;

          if (item.observacao) {
            defaultMotivo += ` - Observação: ${item.observacao}`;
          }

          return defaultMotivo;
        });

        return allMotivos.join('\n');
      })
      .join('\n');

    navigator.clipboard.writeText(copyToClipboard);
  };

  const onSubmitFormalizacao = async () => {
    if (
      Array.isArray(itensModifyChecklist) &&
      itensModifyChecklist.length > 0
    ) {
      copyItensFormalizacao();

      dispatch(
        CheckListDocumentoActions.updateChecklistdocumentoRequest({
          documentId: documento._id,
          itensModify: itensModifyChecklist,
        })
      );
    }
  };

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === TAB_HISTORICO) setChargeHistory(chargeHistory + 1);
    else if (newValue === TAB_EMAILS) loadEmails();
  };

  const loadGrupoAcesso = async () => {
    dispatch(
      GrupoAcessoActions.listRequest({ page: 1, limit: 100, sort: 'name' })
    );
  };

  const handleApprove = (approved) => {
    onSubmit(approved);
  };

  const updateIndexadores = (indexadores) => {
    if (!indexadores) return true;
    if (indexadores == []) return true;
    setIndexadores(indexadores);
  };

  const onSubmit = async (approved) => {
    const currentIndexadores = indexadores.length > 0 ? indexadores : form.indexadores;

    const requiredIndexadores = currentIndexadores.filter(
      (indexador) =>
        (indexador.obrigatorio || indexador.obrigatorioEtapa?.includes(currentEtapa)) &&
        !indexador.valor
    );

    requiredIndexadores.forEach((indexador) => {
      toastr.error(`O indexador "${indexador.nome}" precisa ser preenchido nesta etapa.`);
    });

    if (requiredIndexadores.length) {
      setLoading(false);
      return;
    }

    //await onSubmitFormalizacao();

    if (
      Array.isArray(itensModifyChecklist) &&
      itensModifyChecklist.length > 0
    ) {
      copyItensFormalizacao();

      const headerParams = {
        headers: { Authorization: getToken(), xkeyorg },
      };

      console.log("itensModifyChecklist", itensModifyChecklist)
      console.log("documento._id", documento._id)
      console.log("headerParams", headerParams)

      await api.post(`/checktipos/document/${documento._id}`, itensModifyChecklist, headerParams);
      console.log("Requisição enviada com sucesso");
    }

    const body = {
      data: analise,
      comentarios,
      _id: documento._id,
      idEmpresa: documento.idEmpresa,
      idTipoDocumento: documento.idTipoDocumento,
      idDocumento: documento._id,
      ultimaEtapa: taskId,
      imagens: documento.imagens,
      taskId,
      approve: approved,
    };

    const properties = {
      assunto: form.assunto,
      causaRaiz: form.causaRaiz,
      resumo: form.resumo,
      provisao: form.provisao,
      pedidos: form.pedidos,
      centroDeCusto: form.centroDeCusto,
      pasta: form.pasta,
      UF: form.UF,
      valorDaCausa: form.valorDaCausa
        ? form.valorDaCausa.toString().replace(',', '').replace(/\./g, '')
        : null,
      posicaoParte: form.posicaoParte,
      indexadores: indexadores,
    };

    const indexadoresBody = {
      id: documento._id,
      properties,
    };

    setTimeout(() => {
      dispatch(AnaliseActions.createRequest(body, indexadoresBody));
      setLoading(false); // Redefine `loading` após a submissão ser enviada
    }, 3000);
  };


  function expandLeftSize() {
    setLeftSize(leftSize === 6 ? 12 : 6);
  }

  return (
    <Content>
      <Row>
        <Col md={leftSize} sm={12} style={{ transition: '0.4s ease' }}>
          <Destaque
            form={form}
            leftSize={leftSize}
            expandLeftSize={expandLeftSize}
          />

          <Indexadores
            register={register}
            form={form}
            setForm={setForm}
            updateIndexadores={updateIndexadores}
          />

          <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="panel">
              <div className="formalizacao index-title">
                <span>Formalização</span>
                <button type="button" onClick={expandLeftSize}>
                  {leftSize === 6 ? (
                    <KeyboardArrowRightRoundedIcon />
                  ) : (
                    <KeyboardArrowLeftRoundedIcon />
                  )}
                </button>
              </div>
              <div className="index-inputs" style={{ padding: '10px 30px' }}>
                <Formalizacao
                  selectedDoc={selectedDoc}
                  itensModify={itensModifyChecklist}
                  setItensModify={setItensModifyChecklist}
                />
                <FormalizacaoButtons onSubmit={onSubmitFormalizacao} />
              </div>
            </div>

            <div className="shadow-tab">
              <AppBar position="static">
                <Tabs
                  value={value}
                  onChange={handleTabChange}
                  aria-label="Analise de checklist"
                  variant="fullWidth"
                >
                  <Tab
                    label="Histórico"
                    variant="fullWidth"
                    textColor="primary"
                    icon={<HistoryIcon />}
                  />
                  <Tab
                    label={'E-mails'}
                    variant="fullWidth"
                    textColor="primary"
                    icon={
                      <TooltipEmail>
                        <MailUnreadedIcon />
                      </TooltipEmail>
                    }
                  />
                </Tabs>
              </AppBar>
            </div>

            <TabPanel value={value} index={TAB_HISTORICO}>
              <Historico documento={documento} />
            </TabPanel>
            <TabPanel value={value} index={TAB_EMAILS}>
              <Mails documento={documento} key={documento._id} />
            </TabPanel>

            <div className="panel comments-form">
              <Comentarios
                analise={documento}
                documento={documento}
                handleApprove={handleApprove}
                onSubmitFormalizacao={onSubmitFormalizacao}
              />
            </div>
          </Form>
        </Col>

        {leftSize !== 12 && (
          <Col md={6} sm={12}>
            <div className="panel">
              <div className="index-title">
                <span>Visualizador de documentos</span>
              </div>
              <div className="preview-doc">
                <SearchViewer
                  selectedDoc={documento}
                  colSize={4}
                  haveToRecharge={chargeHistory}
                />
              </div>
            </div>
          </Col>
        )}
      </Row>
      <div
        style={{
          position: 'fixed',
          bottom: '10px',
          right: '10px',
          width: '300px',
          backgroundColor: '#f0f0f0',
          padding: '20px',
          border: '1px solid #ccc',
          boxSizing: 'border-box',
          zIndex: '9999',
          boxShadow: '1px 1px 10px rgba(0,0,0,0.2)',
          display: 'flex',
          slignCenter: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Button
          variant="contained"
          color="primary"
          style={{ color: 'white!important' }}
          onClick={() => {
            setLoading(true);
            onSubmit(true);
          }}
          disabled={loading}
        >
          {loading ? 'Salvando' : 'Avançar'}
        </Button>

        <Link to="/operacao/esteira">
          <Button variant="contained" color="default">
            Voltar
          </Button>
        </Link>
      </div>
    </Content>
  );
}
