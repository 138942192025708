import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import Skeleton from '@material-ui/lab/Skeleton';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';



import { Container, Field, Buttons } from './style';
import IndexerFormFieldFactory from 'components/FormFieldIndexer/IndexerFormFieldFactory';

export default function IndexadoresTpImagemManual({
  document,
  currentFile,
  handleUpdateClassification,
  handleUpdateCurrentIndexadores
}) {
  const { list: listTpImages, loading: loadingTpImages } = useSelector(
    (state) => state.tipoImagem
  );
  const { loading: loadingDocument } = useSelector(
    (state) => state.classificacao
  );

  return (
    <Container>
      <Field>
        <TextField
          name={"numeroCNJ"}
          label={"Número do Cliente"}
          size="small"
          variant="outlined"
          defaultValue={document.numeroCNJ}
          disabled={true}
          tabIndex={-1}
        />
      </Field>
      <Field>
        {loadingTpImages ? (
          <Skeleton variant="rect" width="100%" height={35} />
        ) : (
          <TextField
            name={"tpImg"}
            label={"Tipo de imagem"}
            size="small"
            variant="outlined"
            defaultValue={listTpImages.find((item) => item._id === currentFile.tipoImagem)?.nome}
            disabled={true}
            tabIndex={-1}
          />
        )}
      </Field>
      {currentFile && currentFile.indexadores
        .map((indexador) => (
          <Field>
            <IndexerFormFieldFactory
              searching={false}
              fieldToAdd={null}
              genericParams={{
                indexador,
                checkReadOnly: false,
                multiple: false,
                hasIndexadorError: () => { },
                hasIndexadorTextError: () => { },
                handleChangeIndexador: v => {
                  handleUpdateCurrentIndexadores({ target: { name: indexador.nome, value: v?.target?.value } });
                },
              }}
              identificadorUnicoParams={{}}
            />
          </Field>
        ))}

      <Buttons>
        <Button
          type="button"
          variant="contained"
          color="primary"
          disabled={loadingDocument}
          onClick={handleUpdateClassification}
        >
          Salvar
        </Button>
        <Button
          onClick={(e) => console.log('Voltar')}
          variant="contained"
          color="secondary"
          type="button"
        >
          Voltar
        </Button>
      </Buttons>
    </Container>
  );
}
