import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  TableContainer,
  TableFooter,
  TablePagination,
} from "@material-ui/core";

import Button from "@material-ui/core/Button";

import { Col } from "react-bootstrap";

import { Link, useLocation } from "react-router-dom";
import CustomTableSortLabel from "components/CustomTableSortLabel";
import LoadingTable from "components/LoadingTable";
import StyledTableRow from "components/StyledTableRow";
import ButtonSquare from "components/ButtonSquare";
import ToolTipComponent from "components/Tooltip";
import Modal from "components/Modal";

import { Creators as OcorrenciasActions } from "store/ducks/ocorrencias";

import "./styles.css";

import {
  STATUS_LIST,
} from '../index';

export default function ListOcorrencias() {
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const {
    list: ocorrencias,
    loading,
    total,
    page = 1,
    limit = 10,
    sort = "-dataInicio",
  } = useSelector((state) => state.ocorrencias);

  const {
    access: { servicos },
  } = useSelector((state) => state.permissoes);

  const [request, setRequest] = useState({ page, limit, sort });
  const [userActions, setUserActions] = useState([]);
  const [ocorrencia, setOcorrencia] = useState({});
  const [show, setShow] = useState(false);

  useEffect(() => {
    let find = servicos.find((item) => item.route === pathname);
    if (find !== undefined) {
      setUserActions(find);
    }
  }, [pathname, servicos]);

  useEffect(() => {
    dispatch(
      OcorrenciasActions.listOcorrenciasRequest({
        ...request,
      })
    );
  }, [dispatch, request]);

  useEffect(() => {
    setShow(false);
  }, [loading]);

  function handleChangeRowsPerPage({ target }) {
    const { value: limit } = target;
    setRequest({ ...request, limit, page: 1 });
  }

  function handleChangePage(event, newPage) {
    setRequest({ ...request, page: newPage + 1 });
  }

  function handleSortChange(sort) {
    setRequest({ ...request, sort });
  }

  function openDeleteConfirm(item) {
    setOcorrencia(item);
    setShow(true);
  }

  function handleDelete() {
    dispatch(OcorrenciasActions.deleteOcorrenciasRequest(ocorrencia._id));
  }

  return (
    <>
      <TableContainer className="list-tribunal-container">
        {(ocorrencias && ocorrencias.length > 0) || !!loading ? (
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>
                  <CustomTableSortLabel
                    currentSort={request.sort}
                    sortField="_id"
                    onClick={handleSortChange}
                  >
                    ID
                  </CustomTableSortLabel>
                </TableCell>
                <TableCell>
                  <CustomTableSortLabel
                    currentSort={request.sort}
                    sortField="dataInicio"
                    onClick={handleSortChange}
                  >
                    Data Início
                  </CustomTableSortLabel>
                </TableCell>
                <TableCell>
                  <CustomTableSortLabel
                    currentSort={request.sort}
                    sortField="horaInicio"
                    onClick={handleSortChange}
                  >
                    Hora Início
                  </CustomTableSortLabel>
                </TableCell>

                <TableCell>
                  <CustomTableSortLabel
                    currentSort={request.sort}
                    sortField="dataFinal"
                    onClick={handleSortChange}
                  >
                    Data Final
                  </CustomTableSortLabel>
                </TableCell>
                <TableCell>
                  <CustomTableSortLabel
                    currentSort={request.sort}
                    sortField="horaFinal"
                    onClick={handleSortChange}
                  >
                    Hora Final
                  </CustomTableSortLabel>
                </TableCell>

                <TableCell>
                  <CustomTableSortLabel
                    currentSort={request.sort}
                    sortField="tipo"
                    onClick={handleSortChange}
                  >
                    Tipo
                  </CustomTableSortLabel>
                </TableCell>

                <TableCell align="center">
                  <CustomTableSortLabel
                    currentSort={request.sort}
                    sortField="sistema"
                    onClick={handleSortChange}
                  >
                    Sistema
                  </CustomTableSortLabel>
                </TableCell>

                <TableCell align="center">
                  <CustomTableSortLabel
                    currentSort={request.sort}
                    sortField="classificacao"
                    onClick={handleSortChange}
                  >
                    Classificação
                  </CustomTableSortLabel>
                </TableCell>

                <TableCell align="center">
                  <CustomTableSortLabel
                    currentSort={request.sort}
                    sortField="esteira"
                    onClick={handleSortChange}
                  >
                    Esteira processamento
                  </CustomTableSortLabel>
                </TableCell>

                <TableCell align="center">
                  <CustomTableSortLabel
                    currentSort={request.sort}
                    sortField="chamado"
                    onClick={handleSortChange}
                  >
                    Nº chamado
                  </CustomTableSortLabel>
                </TableCell>

                <TableCell align="center">
                  <CustomTableSortLabel
                    currentSort={request.sort}
                    sortField="status"
                    onClick={handleSortChange}
                  >
                    Status
                  </CustomTableSortLabel>
                </TableCell>

                <TableCell align="center">
                  <CustomTableSortLabel
                    currentSort={request.sort}
                    sortField="observacao"
                    onClick={handleSortChange}
                  >
                    Observação
                  </CustomTableSortLabel>
                </TableCell>

                {userActions.actions &&
                  userActions.actions.map(
                    (userAct, index) =>
                      userAct.action === "editar" && (
                        <TableCell key={index} align="center">
                          Ações
                        </TableCell>
                      )
                  )}
              </TableRow>
            </TableHead>
            <TableBody>
              {!!loading && <LoadingTable columns={5} />}
              {!loading &&
                ocorrencias.map((ocorrencia) => (
                  <StyledTableRow key={ocorrencia._id}>

                    <TableCell>{ocorrencia._id}</TableCell>
                    <TableCell>{ocorrencia.dataInicio}</TableCell>
                    <TableCell>{ocorrencia.horaInicio}</TableCell>
                    <TableCell>{ocorrencia.dataFinal}</TableCell>
                    <TableCell>{ocorrencia.horaFinal}</TableCell>
                    <TableCell>{ocorrencia.tipo}</TableCell>
                    <TableCell>{ocorrencia.sistema}</TableCell>
                    <TableCell>{ocorrencia.classificacao}</TableCell>
                    <TableCell>{ocorrencia.esteira}</TableCell>
                    <TableCell>{ocorrencia.chamado}</TableCell>
                    <TableCell>{ocorrencia.status ? STATUS_LIST[0].name : STATUS_LIST[1].name}</TableCell>
                    <TableCell>{ocorrencia.observacao}</TableCell>

                    {userActions.actions &&
                      userActions.actions.map(
                        (userAct, index) =>
                          userAct.action === "editar" && (
                            <TableCell align="center" key={index}>
                              <div className="flex-direction">
                                <ToolTipComponent
                                  title="Editar"
                                  placement="top"
                                >
                                  <Link
                                    to={{
                                      pathname: "/configuracao/ocorrencia/edit",
                                      state: { ocorrencia },
                                    }}
                                    className="linkicon"
                                    id="ocorrencia"
                                  >
                                    <ButtonSquare icon="edit" />
                                  </Link>
                                </ToolTipComponent>
                                <ToolTipComponent
                                  title="Remover"
                                  placement="top"
                                >
                                  <div>
                                    <ButtonSquare
                                      onClick={() => openDeleteConfirm(ocorrencia)}
                                      icon="delete"
                                    />
                                  </div>
                                </ToolTipComponent>
                              </div>
                            </TableCell>
                          )
                      )}
                  </StyledTableRow>
                ))}
            </TableBody>
            {!loading && (
              <TableFooter>
                <TableRow>
                  <TablePagination
                    count={total}
                    page={request.page - 1}
                    rowsPerPage={request.limit}
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    labelRowsPerPage="Linhas por página"
                    labelDisplayedRows={({ from, to, count }) =>
                      `${from}-${to} de ${count !== -1 ? count : `mais que ${to}`
                      }`
                    }
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    onPageChange={handleChangePage}
                  />
                </TableRow>
              </TableFooter>
            )}
          </Table>
        ) : (
          <div className="list-tribunal-content">
            <span className="list__none">Não há ocorrencias registradas.</span>
          </div>
        )}
      </TableContainer>
      <Modal
        isOpen={show}
        title="Remover ocorrência:"
        onClose={setShow}
        className="modal-padding"
      >
        <p>Deseja realmente remover esta ocorrência {ocorrencia.nome}?</p>

        <Col md={12}>
          <div className="button-container">
            <Button
              variant="contained"
              color="primary"
              onClick={(e) => handleDelete()}
            >
              Sim
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={(e) => {
                setShow(false);
              }}
            >
              Não
            </Button>
          </div>
        </Col>
      </Modal>
    </>
  );
}
